.Root__Index .Logo__Partial {
  width: 100%; }

.Root__Index__Logos {
  padding-bottom: 30px;
  border-bottom: 1px solid #002c4e; }

.Root__Latest {
  width: 100%;
  margin-top: 60px; }
  .Root__Latest .BlogBox {
    width: 100%;
    max-width: 1200px;
    margin: 0; }

.Root__Blogs {
  display: block;
  margin-bottom: 80px; }

@media (min-width: 768px) {
  .Root__Index__Logos {
    display: flex;
    align-items: flex-end;
    padding-bottom: 60px; }
    .Root__Index__Logos__item {
      flex: 1; }
  .Root__Blogs {
    display: flex;
    flex-wrap: wrap; } }

.Root__About {
  font-size: 2.5em; }
  .Root__About__Content {
    font-weight: 200; }
